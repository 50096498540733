export const getItem = key => {
  // return window.localStorage.getItem(key);
  return window.sessionStorage.getItem(key);
};

export const setItem = (key, value) => {
  // window.localStorage.setItem(key, value);
  window.sessionStorage.setItem(key, value);
};

export const removeItem = (key, value) => {
  // window.localStorage.removeItem(key, value);
  window.sessionStorage.removeItem(key, value);
}