import React from 'react'
import { Route, Redirect } from 'react-router-dom';

// import useAuth from 'src/hooks/useAuth';

import * as Utils from 'src/Utils';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// function PrivateRoute({ children, ...rest }) {
function CompanyAdminRoute({ children, render, ...rest }) {
  // let auth = useAuth();

  // const { role } = useAuth();

  // console.log('ROLE:', role);

  // const isAdmin = role === 'SUPER_ADMIN';
  const isAdmin = Utils.getItem('role') === 'COMPANY_ADMIN';

  // const userData = Utils.getItem('loginData');
  // const isAdmin = userData && JSON.parse(userData).username === 'admin';

  return (
    <Route
      {...rest}
      render={({ location }) =>
        // auth.user ? (
        isAdmin ? (
          // children
          render()
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default CompanyAdminRoute;